import { Box, useColorMode } from '@chakra-ui/react'

import Footer from './Footer'
import Header from './Header'

export interface MainLayoutProps {
  children?: React.ReactNode
  footerTheme?: 'light' | 'dark'
  footerSticky?: boolean
  noPaddingTop?: boolean
  noHeaderLogo?: boolean
}

const MainLayout = (props: MainLayoutProps) => {
  const { colorMode } = useColorMode()
  return (
    <>
      <Header noHeaderLogo={props.noHeaderLogo} />
      <Box
        as="main"
        zIndex={1}
        position="relative"
        bg={colorMode === 'dark' ? 'black' : 'white'}
        transitionProperty="background-color"
        transitionDuration="0.3s"
        pt={props.noPaddingTop ? 0 : '100px'}
      >
        {props.children}
      </Box>
      <Footer
        footerTheme={props.footerTheme}
        footerSticky={props.footerSticky}
      />
    </>
  )
}

export default MainLayout
