import { MotionBox } from '@/components'
import React from 'react'
import { Variants } from 'framer-motion'

export interface HeaderNavMenuItemProps {
  children?: React.ReactNode
}

const HeaderNavMenuItem = (props: HeaderNavMenuItemProps) => {
  const item: Variants = {
    closed: {
      x: '150%',
      transition: {
        duration: 0.5,
      },
    },
    open: {
      x: '0%',
      transition: {
        duration: 0.5,
      },
    },
  }
  return <MotionBox variants={item}>{props.children}</MotionBox>
}

export default HeaderNavMenuItem
