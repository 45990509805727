import { Box, Flex } from '@chakra-ui/react'
import React, { useState } from 'react'

import ChacraLogo from '@/assets/images/logo-green.png'
import HeaderLogo from './HeaderLogo'
import HeaderNavButton from './HeaderNavButton'
import HeaderNavLink from './HeaderNavLink'
import HeaderNavMenu from './HeaderNavMenu'
import HeaderNavMenuItem from './HeaderNavMenuItem'
import Link from 'next/link'

export interface HeaderProps {
  noHeaderLogo?: boolean
}

const Header = (props: HeaderProps) => {
  const [isNavMenuOpen, setIsNavMenuOpen] = useState(false)

  const toggleNavMenuOpen = () => setIsNavMenuOpen(!isNavMenuOpen)

  return (
    <Flex
      as="header"
      position="absolute"
      top="0"
      left="0"
      w="full"
      justifyContent="space-between"
      alignItems="center"
      px={{ base: '4', md: '6', lg: '12' }}
      h="100px"
      zIndex="sticky"
    >
      {props.noHeaderLogo ? (
        <Box />
      ) : (
        <Link href="/">
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <a>
            <HeaderLogo src={ChacraLogo} />
          </a>
        </Link>
      )}
      <HeaderNavButton isOpen={isNavMenuOpen} onClick={toggleNavMenuOpen} />
      <HeaderNavMenu
        isOpen={isNavMenuOpen}
        onClose={toggleNavMenuOpen}
        backgroundColor="rgba(0,0,0,0.7)"
      >
        <HeaderNavMenuItem>
          <HeaderNavLink href="/">Home</HeaderNavLink>
        </HeaderNavMenuItem>
        <HeaderNavMenuItem>
          <HeaderNavLink href="/posts">Blogs</HeaderNavLink>
        </HeaderNavMenuItem>
        <HeaderNavMenuItem>
          <HeaderNavLink href="/portfolio">Portfolio</HeaderNavLink>
        </HeaderNavMenuItem>
        <HeaderNavMenuItem>
          <HeaderNavLink href="/contact-us">Contact Us</HeaderNavLink>
        </HeaderNavMenuItem>
        <HeaderNavMenuItem>
          <HeaderNavLink
            href="https://www.clutch.co/profile/chacra-software-solutions-0#reviews"
            isExternal
          >
            Clutch Reviews
          </HeaderNavLink>
        </HeaderNavMenuItem>
      </HeaderNavMenu>
    </Flex>
  )
}

export default Header
