import { Link, SystemProps } from '@chakra-ui/react'

import NextLink from 'next/link'
import React from 'react'

export interface HeaderNavLinkProps {
  children?: React.ReactNode
  href: string
  fontSize?: SystemProps['fontSize']
  textAlign?: SystemProps['textAlign']
  textDecoration?: SystemProps['textDecoration']
  mt?: SystemProps['mt']
  color?: SystemProps['color']
  _hover?: SystemProps['_hover']
  isExternal?: boolean
}

const HeaderNavLink = (props: HeaderNavLinkProps) => {
  return (
    <NextLink href={props.href} passHref>
      <Link
        display="block"
        fontSize={props.fontSize ?? { base: '2xl', md: '3xl', lg: '4xl' }}
        textAlign={props.textAlign}
        fontWeight="bold"
        textDecoration={props.textDecoration}
        color={props.color ?? 'white'}
        mt={props.mt}
        _hover={{
          color: 'primary',
          ...props._hover,
        }}
        isExternal={props.isExternal}
      >
        {props.children}
      </Link>
    </NextLink>
  )
}

export default HeaderNavLink
