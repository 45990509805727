import {
  Box,
  Center,
  Container,
  DarkMode,
  Flex,
  HStack,
  Heading,
  LightMode,
  Link,
  Text,
  VStack,
  VisuallyHidden,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { BsInstagram } from 'react-icons/bs'
import { FaFacebookF } from 'react-icons/fa'
import FooterLetsTalkForm from './FooterLetsTalkForm'
import Image from 'next/image'
import NextLink from 'next/link'
import { RiLinkedinFill } from 'react-icons/ri'
import WebsiteSignature from '@/assets/images/web-signature.svg'
import useWindowSize from '@/hooks/useWindowSize'

export interface FooterProps {
  footerTheme?: 'light' | 'dark'
  footerSticky?: boolean
}
const Footer = ({ footerTheme = 'dark', footerSticky }: FooterProps) => {
  const [isStickyFooter, setIsStickyFooter] = useState<boolean>(false)

  const { height, width } = useWindowSize()

  useEffect(() => {
    setIsStickyFooter(height >= 759 && width >= 992)
  }, [height, width])

  return (
    <Flex
      as="footer"
      flex={1}
      minH="100vh"
      w="full"
      position={
        footerSticky !== undefined
          ? footerSticky
            ? 'sticky'
            : 'static'
          : isStickyFooter
          ? 'sticky'
          : 'static'
      }
      bottom={0}
      left={0}
      zIndex={0}
      flexDirection="column"
    >
      {footerTheme === 'dark' ? (
        <DarkMode>
          <Flex flex={1} h="full" bgColor="black">
            <Container
              display="flex"
              maxW="container.xl"
              py="10"
              justifyContent="center"
              flexDirection="column"
            >
              <Heading
                textAlign="center"
                size="2xl"
                mb="6"
                color="white"
                _mediaDark={{ color: 'white' }}
              >
                Let&apos;s Talk
              </Heading>
              <Heading
                as="h3"
                textAlign="center"
                color="white"
                _mediaDark={{ color: 'white' }}
                fontWeight="normal"
                mb={8}
              >
                Get a free confidential consultation
              </Heading>
              <Container mx="auto" maxW="container.md">
                <FooterLetsTalkForm />
              </Container>
            </Container>
          </Flex>
        </DarkMode>
      ) : (
        <LightMode>
          <Flex flex={1} h="full">
            <Container
              display="flex"
              maxW="container.xl"
              pt="100px"
              pb="10"
              justifyContent="center"
              flexDirection="column"
            >
              <Heading textAlign="center" size="2xl" mb="6">
                Let&apos;s Talk
              </Heading>
              <Heading
                as="h3"
                textAlign="center"
                color="gray.800"
                _dark={{ color: 'white' }}
                fontWeight="normal"
                mb={8}
              >
                Get a free confidential consultation
              </Heading>
              <Container mx="auto" maxW="container.md">
                <FooterLetsTalkForm />
              </Container>
            </Container>
          </Flex>
        </LightMode>
      )}
      <Box
        flexShrink={0}
        borderTop="4px"
        borderColor="primary"
        px="4"
        bg="black"
      >
        <Container
          maxW="container.xl"
          display="flex"
          minH={{ base: '400px', md: 'unset' }}
          flexDirection={{ base: 'column', lg: 'row' }}
          alignItems="center"
        >
          <VStack
            flex="1"
            alignItems={{ base: 'center', lg: 'flex-start' }}
            spacing="4"
            py="8"
            order={{ base: 3, lg: 1 }}
          >
            <NextLink href="/privacy-policy" passHref>
              <Text as="a" color="white">
                Privacy Policy
              </Text>
            </NextLink>
            <Text color="gray.500">© Chacra Software Solutions</Text>
            <Text color="gray.500">All Rights Reserved</Text>
          </VStack>
          <Box
            flex="1"
            order={{ base: 1, lg: 2 }}
            py={{ base: 8, lg: 0 }}
            w="full"
          >
            <Box position="relative" h="120px" w="full">
              <Image
                src={WebsiteSignature}
                layout="fill"
                objectFit="contain"
                draggable={false}
                alt="chacra-signature"
              />
            </Box>
          </Box>
          <HStack
            flex="1"
            justifyContent={{ base: 'flex-start', lg: 'flex-end' }}
            spacing="4"
            py={{ base: 0, lg: 8 }}
            order={{ base: 2, lg: 3 }}
          >
            <Link
              bg="white"
              _hover={{ bg: 'primary', color: 'white' }}
              width="38px"
              height="38px"
              rounded="lg"
              href="https://www.linkedin.com/company/chacrasoftware"
              isExternal
            >
              <VisuallyHidden>Chacra Software LinkedIn</VisuallyHidden>
              <Center h="full">
                <RiLinkedinFill size={24} />
              </Center>
            </Link>
            <Link
              bg="white"
              _hover={{ bg: 'primary', color: 'white' }}
              width="38px"
              height="38px"
              rounded="lg"
              href="https://www.facebook.com/ChacraSoftware"
              isExternal
            >
              <VisuallyHidden>Chacra Software Facebook</VisuallyHidden>
              <Center h="full">
                <FaFacebookF size={24} />
              </Center>
            </Link>
            <Link
              bg="white"
              _hover={{ bg: 'primary', color: 'white' }}
              width="38px"
              height="38px"
              rounded="lg"
              href="https://www.instagram.com/chacrasoftware"
              isExternal
            >
              <VisuallyHidden>Chacra Software Instagram</VisuallyHidden>
              <Center h="full">
                <BsInstagram size={24} />
              </Center>
            </Link>
          </HStack>
        </Container>
      </Box>
    </Flex>
  )
}

export default Footer
