import Image, { StaticImageData } from 'next/image'
import { MotionBox } from '@/components'
import React from 'react'

export interface HeaderLogoProps {
  src: string | StaticImageData
}

const HeaderLogo = (props: HeaderLogoProps) => {
  return (
    <MotionBox
      h="full"
      w={{ base: '150px', md: '160px', lg: '180px' }}
      position="relative"
    >
      <Image src={props.src} layout="responsive" priority alt="chacra-logo" />
    </MotionBox>
  )
}

export default HeaderLogo
