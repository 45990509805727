import { Button, useBreakpointValue } from '@chakra-ui/react'

import { MotionBox } from '@/components'
import React from 'react'
import { Variants } from 'framer-motion'

export interface HeaderNavButtonProps {
  isOpen?: boolean
  onClick?: (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  theme?: 'dark' | 'light'
}

const HeaderNavButton = ({
  theme = 'light',
  ...props
}: HeaderNavButtonProps) => {
  const color = theme === 'light' ? 'black' : 'white'
  const state = props.isOpen ? 'open' : 'closed'
  const y = useBreakpointValue({ base: 2, md: 3 })

  const bar1: Variants = {
    closed: {
      rotate: 0,
      y: 0,
    },
    open: (y) => ({
      rotate: 45,
      y,
      marginBottom: 0,
    }),
  }

  const bar2: Variants = {
    closed: { rotate: 0, y: 0 },
    open: (y) => ({ rotate: -45, y: -y }),
  }

  return (
    <Button
      aria-label="Nav Button"
      variant="unstyled"
      rounded={0}
      h={{ base: '40px', md: '60px' }}
      w={{ base: '40px', md: '60px' }}
      position="relative"
      onClick={props.onClick}
      zIndex={1301}
    >
      <MotionBox
        variants={bar1}
        animate={state}
        custom={y}
        w={{ base: '40px', md: '60px' }}
        h={{ base: '4px', md: '6px' }}
        bgColor={props.isOpen ? 'white' : color}
        mb="2"
      />
      <MotionBox
        variants={bar2}
        animate={state}
        custom={y}
        w={{ base: '40px', md: '60px' }}
        h={{ base: '4px', md: '6px' }}
        bgColor={props.isOpen ? 'white' : color}
      />
    </Button>
  )
}

export default HeaderNavButton
