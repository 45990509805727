import { AnimatePresence, Variants } from 'framer-motion'
import { FlexProps, VStack } from '@chakra-ui/react'
import React, { useCallback, useEffect } from 'react'

import { MotionFlex } from '@/components'

export interface HeaderNavMenuProps {
  isOpen?: boolean
  children?: React.ReactNode
  backgroundColor?: FlexProps['backgroundColor']
  onClose?: (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
}

const HeaderNavMenu = (props: HeaderNavMenuProps) => {
  const escFunction = useCallback(
    (event) => {
      if (event.keyCode === 27) {
        if (props.onClose) props.onClose()
      }
    },
    [props]
  )

  useEffect(() => {
    if (props.isOpen) {
      document.addEventListener('keydown', escFunction, false)
      document.body.style.overflow = 'hidden'
    }
    return () => {
      document.removeEventListener('keydown', escFunction, false)
      document.body.style.overflow = 'unset'
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isOpen])

  const container: Variants = {
    closed: {
      opacity: 0,
      transition: {
        staggerChildren: 0.1,
        when: 'afterChildren',
        staggerDirection: -1,
      },
    },
    open: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        when: 'beforeChildren',
      },
    },
  }
  return (
    <AnimatePresence>
      {props.isOpen && (
        <MotionFlex
          key="nav-menu-container"
          position="absolute"
          top={0}
          left={0}
          h="100vh"
          w="full"
          zIndex="overlay"
          variants={container}
          initial="closed"
          animate="open"
          exit="closed"
          backgroundColor={props.backgroundColor}
          onClick={props.onClose}
        >
          <VStack
            w="full"
            alignItems="flex-end"
            pt="120px"
            px={{ base: '4', md: '6', lg: '12' }}
            spacing={4}
            overflow="hidden"
          >
            {props.children}
          </VStack>
        </MotionFlex>
      )}
    </AnimatePresence>
  )
}

export default HeaderNavMenu
