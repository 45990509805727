import { Container, ContainerProps, Flex, forwardRef } from '@chakra-ui/react'

export type Section2Props = ContainerProps

const Section2 = forwardRef<Section2Props, 'section'>((props, ref) => {
  return (
    <Container
      as="section"
      ref={ref}
      maxW="container.lg"
      h="100vh"
      my={4}
      {...props}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        h="full"
      >
        {props.children}
      </Flex>
    </Container>
  )
})

export default Section2
