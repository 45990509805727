import 'yup-phone'

import * as yup from 'yup'

import {
  Box,
  Button,
  Center,
  FormControl,
  Input,
  Text,
  VStack,
  useToast,
} from '@chakra-ui/react'
import { ContactUs, ContactUsVariables } from '@/apollo/__generated__/ContactUs'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useEffect, useRef } from 'react'

import { CONTACT_US } from '@/apollo/mutation'
import { ContactUsInput } from '@/apollo/__generated__/globalTypes'
import { Form } from '@/components'
import ReCAPTCHA from 'react-google-recaptcha'
import { useMutation } from '@apollo/client'
import { yupResolver } from '@hookform/resolvers/yup'

const schema = yup.object({
  name: yup.string().required(),
  email: yup.string().email().required(),
  phone: yup.string().required(),
  projectDescription: yup.string().required(),
})

const FooterLetsTalkForm = () => {
  const reCaptchaRef = useRef<any>()

  const toast = useToast()

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ContactUsInput>({ resolver: yupResolver(schema) })

  const [contactUs, { loading }] = useMutation<ContactUs, ContactUsVariables>(
    CONTACT_US
  )

  const onSubmit: SubmitHandler<ContactUsInput> = async (formData) => {
    try {
      await reCaptchaRef.current.executeAsync()
      await contactUs({ variables: { contactUsInput: formData } })
      reset()
      toast({
        status: 'success',
        render: () => (
          <Box
            border="1px"
            borderColor="primary"
            p={6}
            rounded="lg"
            bgColor="black"
          >
            <Text color="white" fontWeight="bold" textAlign="center">
              Message Sent
            </Text>
          </Box>
        ),
      })
    } catch (error) {
      console.error(error)
      toast({
        containerStyle: {
          marginBottom: '100px',
        },
        status: 'error',
        render: () => (
          <Box
            border="1px"
            borderColor="red.500"
            p={6}
            rounded="lg"
            bgColor="black"
          >
            <Text color="white" fontWeight="bold" textAlign="center">
              Something went wrong
            </Text>
          </Box>
        ),
      })
    }
  }

  useEffect(() => {
    if (
      errors.email?.message === 'email must be a valid email' &&
      !toast.isActive('email-error')
    ) {
      toast({
        id: 'email-error',
        status: 'error',
        render: () => (
          <Box
            border="1px"
            borderColor="red.500"
            p={6}
            rounded="lg"
            bgColor="black"
          >
            <Text color="white" fontWeight="bold" textAlign="center">
              Invalid email address
            </Text>
          </Box>
        ),
      })
    }
  }, [errors.email?.message, errors.phone?.message, toast, isSubmitting])
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      <ReCAPTCHA
        ref={reCaptchaRef}
        size="invisible"
        sitekey="6Lc4XT4eAAAAAN-tSB27-ZclotjjPpVU80dTi1-Q"
      />
      <VStack spacing="6" mb="12">
        <FormControl isInvalid={!!errors.name}>
          <Input
            placeholder="Name"
            variant="underline"
            size="lg"
            {...register('name', { required: true })}
          />
        </FormControl>
        <FormControl isInvalid={!!errors.email}>
          <Input
            placeholder="Email"
            type="email"
            variant="underline"
            size="lg"
            {...register('email', { required: true })}
          />
        </FormControl>
        <FormControl isInvalid={!!errors.phone}>
          <Input
            placeholder="Phone"
            variant="underline"
            type="tel"
            size="lg"
            {...register('phone', { required: true })}
          />
        </FormControl>
        <FormControl isInvalid={!!errors.projectDescription}>
          <Input
            placeholder="Project Details"
            variant="underline"
            size="lg"
            {...register('projectDescription', { required: true })}
          />
        </FormControl>
      </VStack>
      <Center>
        <Button
          id="footer-cta-form-submit"
          size="lg"
          isFullWidth
          maxW="400px"
          type="submit"
          isLoading={loading}
        >
          Send Request
        </Button>
      </Center>
    </Form>
  )
}

export default FooterLetsTalkForm
