import { Container, ContainerProps, forwardRef } from '@chakra-ui/react'

export interface SectionProps extends ContainerProps {}

/**
 * Section component
 */
const Section = forwardRef<SectionProps, 'section'>((props, ref) => {
  return (
    <Container
      as="section"
      ref={ref}
      maxW="container.lg"
      my={4}
      mx="auto"
      textAlign="center"
      {...props}
    >
      {props.children}
    </Container>
  )
})

export default Section
